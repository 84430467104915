.flexInput{
    display: flex;
    align-items: center;
    flex-direction: start;
    gap: 15px;
    width: 100%;
    /* margin-bottom: 5px; */
}
.StageField{
    margin-bottom: 15px;
}

.RadioBtnsArea{
    display: flex;   
    gap: 5px ;
    /* flex-direction: start;     */
}

.radioInput{
    width: 13px;
    height: 13px;    
}

.addLessonTextarea{
    border-radius: 12px;
    background-color: #ffffff;
    padding: 5px 10px;
    width: 100%;
    height: 40px;
    border: 2px solid #dbdbdb;
    outline: none;
}