.mytextarea {
    border-radius: 12px;
    background-color: #ffffff;
    padding: 5px 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #dbdbdb;
    outline: none;
    resize: none;
    overflow-y: auto;
    scrollbar-gutter: stable;
}

.mytextarea::-webkit-scrollbar {
    width: 6px;
}

.mytextarea::-webkit-scrollbar-track {
    background: #dfdfdf93;
    /* position: absolute; */
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 6px;
    /* border: 3px solid transparent; */
}

.mytextarea::-webkit-scrollbar-thumb {
    background-color: #246FFB;
    border-radius: 6px;
    /* border: 3px solid transparent; */
}

.mytextarea:focus {
    border-color: #246FFB;
    box-shadow: 0 0 10px rgba(83, 143, 255, 0.466);
    outline: none;
}

.error {
    width: 100%;
    color: #da0000;
    font-weight: 600;
}