
.DifficultInput { 
    border-radius: 2px;
}

.DifficultInput h2 {
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
}


.DifficultInput,
.DifficultInput div {
    display: flex;
    align-items: center;
    gap: 0;
    height: 8px;
    /* margin: 0 1px; */
    border-width: 0px 1px;
    border: 1px solid #fff;
    border-width: 0px 3px;
    border-radius: 2px;
    margin: 0;

}

.DifficultInput div:nth-child(2) {
    background-color: #00ff10;
    background: linear-gradient(90deg rgba(0, 255, 16, 1) 0%, rgba(234, 247, 12, 1) 100%);
}

.DifficultInput div:nth-child(3) {
    background-color: #eaf70c;
    background: linear-gradient(90deg, rgba(234, 247, 12, 1) 0%, rgba(255, 226, 0, 1) 10%);
}

.DifficultInput div:nth-child(4) {
    background-color: #ffa200;
    background: linear-gradient(90deg, rgb(255 206 0) 0%, rgb(255 120 0)100%);}

.DifficultInput div:nth-child(5) {
    background-color: #fe5000;
    background: linear-gradient(90deg, rgba(255, 76, 0, 1) 0%, rgba(254, 0, 0, 1) 100%);
}

.DifficultInput div:nth-child(6) {
    background-color: #b700fe;
    background: linear-gradient(90deg, rgb(215 0 0) 0%, rgb(163 0 0) 100%);}


.DifficultInput input {
    padding: 0;
    appearance: none;
    width: 30px;
    height: 8px;
    border-radius: 2px;
    position: relative;

}

.DifficultInput input::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    border-radius: 2px;
    transition: background-color 0.2s linear;
}

.DifficultInput input:hover::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    border-radius: 2px;
    outline: 2px solid #e9e6e6;
}

.DifficultInput input:checked::before {
    box-shadow: 0px 0px 4px #434343bf;
}

.DifficultInput label {
    appearance: none;
}